import { assignRandomNumberToInput } from '@utilities/random-number-utilities';

export const getClientCanonicalId = (result: any): string => {
  if (result) {
    return hasCost(result)
      ? result.cost[0].client_canonical_id
      : result.client_canonical_id;
  } else {
    return '';
  }
};

export const getIncentiveAmount = (provider: any): string => {
  if (
    provider.cost &&
    provider.cost[0] &&
    provider.cost[0].member_costs &&
    provider.cost[0].member_costs.average.incentive_amount
  ) {
    return (
      '$' +
      Math.round(
        parseFloat(provider.cost[0].member_costs.average.incentive_amount)
      ).toLocaleString()
    );
  }
  return null;
};

export const getCostData = (provider: any, type: string): string => {
  if (
    provider.cost &&
    provider.cost[0] &&
    provider.cost[0].member_costs &&
    provider.cost[0].member_costs.average[type] != null &&
    provider.cost[0].member_costs.average[type] >= 0
  ) {
    return (
      '$' +
      Math.round(
        parseFloat(provider.cost[0].member_costs.average[type])
      ).toLocaleString()
    );
  }
  return null;
};

export const termsOfUseAccepted = (): boolean => {
  // Terms must be accepted in session on SERP or once on profile
  return (
    !JSON.parse(sessionStorage.getItem('termsAccepted')) === false ||
    !JSON.parse(localStorage.getItem('termsAccepted')) === false
  );
};

const hasCost = (result): boolean => {
  return result.cost && result.cost[0];
};

export const mapAwards = (provider: any): string => {
  provider = provider || {};
  let awards: string = 'awards_count';
  if (provider.coe_info && provider.coe_info !== '') {
    awards = 'coe_info';
  } else if (provider.bdc_info && provider.bdc_info !== '') {
    awards = 'bdc_info';
  }
  return awards;
};

export const isProviderInMedicalGroup = (
  memberMedGroupNos: string[],
  providerMedGroupNos: string[]
): boolean => {
  return (
    memberMedGroupNos?.filter((groupNo) =>
      providerMedGroupNos?.includes(groupNo)
    ).length > 0
  );
};

export const getPlaceholderURL = (provider) => {
  let placeholderURL = '';
  const placeholderId = assignRandomNumberToInput(provider.id.toString(), 3);

  if (provider.provider_type === 'F') {
    placeholderURL = `dls-facility-${placeholderId}`;
  }

  if (provider.gender === 'F') {
    placeholderURL = `dls-provider-female-${placeholderId}`;
  }

  if (provider.gender === 'M') {
    placeholderURL = `dls-provider-male-${placeholderId}`;
  }

  if (
    provider.provider_type === 'P' &&
    (!provider.gender || provider.gender === 'U' || provider.gender === 'N')
  ) {
    placeholderURL = `dls-provider-nonbinary-${placeholderId}`;
  }

  return placeholderURL;
};

export const getGenderTranslation = (translateService, provider) => {
  switch (provider?.gender?.toLowerCase()) {
    case 'f':
      return translateService.instant('app_global_female');
    case 'm':
      return translateService.instant('app_global_male');
    case 'n':
      return translateService.instant('app_global_non_binary');
    case 'u':
      return translateService.instant('app_global_unknown_gender');
    default:
      return '';
  }
};

<div
  class="text-center text-palette-m3-system-gray-10 flex flex-col items-center p-4 md:p-0"
>
  <h3
    class="heading-3 font-bold"
    [innerHTML]="'network_selection_wizard_plan_type_header' | translate"
  ></h3>
  <div
    class="text-base mt-2 mb-10 max-w-[582px]"
    [innerHTML]="'network_selection_wizard_plan_type_description' | translate"
  ></div>
  <app-wizard-card-layout-template>
    <app-wizard-selection-card
      *ngFor="let card of marketSegmentData; let i = index"
      [attr.data-cy]="'network-selection-wizard-plan-type-selection' + i"
      [title]="card.title | translate"
      [description]="card.description | translate"
      [ctaText]="card.ctaText | translate"
      (cta)="setMarketSegment(card.market_segment)"
    >
    </app-wizard-selection-card>
  </app-wizard-card-layout-template>
  <app-wizard-login-link></app-wizard-login-link>
</div>

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SearchParams } from '@interfaces/search-params.model';
import { AppParamsService } from '../app.params.service';
import { TranslateService } from '@ngx-translate/core';
import { UUID } from 'angular2-uuid';
import { SearchSummary } from '@interfaces/search-summary.interface';
import { MemberParams } from '@interfaces/member-params.model';
import { map, switchMap } from 'rxjs/operators';
import { ConfigurationService } from '@services/configuration.service';
import { clone, extend, identity, pickBy } from 'lodash';
import { SearchParamsService } from '@components/+search/search-params.service';
import { RouteUtilities } from '@utilities/route.utilities';
import { SearchFilterV2 } from '@interfaces/search-filter-v2.model';
import { SearchFacetParamsService } from '@components/+search/classes/search-facet-params.service';

@Injectable({
  providedIn: 'root',
})
export class SerpService {
  public httpParams: HttpParams;

  constructor(
    private http: HttpClient,
    private appParams: AppParamsService,
    private translate: TranslateService,
    private configurationService: ConfigurationService,
    private searchParamsService: SearchParamsService,
    private routeUtilities: RouteUtilities,
    private searchFacetParamsService: SearchFacetParamsService
  ) {}

  /**
   * Get rates serp summary
   * @param params: SearchParams
   * @returns Observable<SearchSummary>
   */

  public requestSerpSummary(
    params: SearchParams,
    searchFilters: SearchFilterV2[]
  ): Observable<SearchSummary> {
    const url = `/api/providers/summary.json`;
    this.httpParams = this.getHttpRequestParams(
      this.cloneParams(params),
      searchFilters
    );
    const encodedParams = this.routeUtilities.manuallyEncodeParams(
      this.httpParams
    );

    return this.configurationService.signatureResolved().pipe(
      switchMap(() =>
        this.http
          .get(`${url}${encodedParams}`, {
            withCredentials: true,
          })
          .pipe(map((data: SearchSummary) => data || null))
      )
    );
  }

  private getHttpRequestParams(
    params: SearchParams,
    searchFilters: SearchFilterV2[]
  ): HttpParams {
    this.extendFormattedParams(params);
    // update tiers to tiers_facetable
    this.searchParamsService.updateTiersFacetableParam(params);
    // Get truthy params only
    params = pickBy(params, identity);
    // Generate HttpParams object
    let httpParams = new HttpParams({ fromObject: params as any });
    // Update HttpParams for multi#and/or filter selection logic
    httpParams = this.searchFacetParamsService.updateMultiSelectFilterParams(
      httpParams,
      searchFilters
    );
    return httpParams;
  }

  private extendFormattedParams(params: SearchParams) {
    // Add member params
    extend(params, this.getMemberParams(params));
    // Add locale
    params.locale = this.getLocale();
    // Add transaction id
    params.transaction_id = UUID.UUID();
    // Add account id
    params.account_id = this.configurationService.account_id.getValue();
    return params;
  }

  private cloneParams(params: SearchParams): SearchParams {
    return (params = clone(params));
  }

  private getMemberParams(params): MemberParams {
    const memberParams = this.appParams.getMemberParams();
    params = new MemberParams(memberParams);
    return params;
  }

  private getLocale(): string {
    let lang = 'en';
    if (this.translate.currentLang) {
      lang = this.translate.currentLang;
    }
    return lang;
  }
}

<div
  class="msa-form-container flex justify-center text-center"
  *ngIf="settings?.form?.inputs"
>
  <zelis-card
    [disableHover]="true"
    [elevated]="true"
    class="msa-form-card w-[80%] max-w-[800px]"
  >
    <mat-card-content>
      <div class="msa-header flex flex-col justify-center">
        <img
          mat-card-image
          class="logo-img object-scale-down max-h-10 w-auto max-w-full"
          [src]="(headerLogo | async)?.header?.primary_logo"
          alt="Logo"
        />
        <h5 class="mt-2">
          {{ 'msa_member_impersonate_form_heading' | translate }}
        </h5>
        <p class="my-3">
          {{ 'msa_member_impersonate_intro_text' | translate }}
        </p>
      </div>
      <div class="msa-important" *ngIf="formSubmitted">
        {{ 'msa_members_error' | translate }}
      </div>

      <div
        class="msa-inputs my-6"
        *ngFor="let input of settings.form.inputs; trackBy: trackByFn"
        (change)="disableSubmit()"
      >
        <mat-form-field
          *ngIf="input.type === 'text'"
          floatLabel="auto"
          class="msa-input text-xs w-[350px] my-4"
          [class.no-border]="input.type !== 'date'"
        >
          <mat-label *ngIf="input.type !== 'select'" [attr.for]="input.title">
            {{ input.title | translate }}
          </mat-label>
          <input
            class="p-0"
            matInput
            [(ngModel)]="input.model"
            [attr.name]="input.title"
            [attr.minLength]="input.minLength"
            [attr.maxlength]="input.maxLength"
            [ngClass]="{ 'has-error': shouldShowTextError(input) }"
            data-cy="msa.form.text-input"
            (input)="
              input.model.length === input.minLength ? disableSubmit() : ''
            "
            required
          />
          <mat-hint class="msa-hint">{{ input.hint | translate }}</mat-hint>
          <mat-error *ngIf="shouldShowTextError(input)" class="msa-important">
            {{ 'msa_member_impersonate_length_error' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="input.type === 'date'" class="w-[350px]">
          <mat-label
            *ngIf="input.type !== 'select'"
            [attr.for]="input.title"
            class="overflow-visible"
          >
            {{ input.title | translate }}
          </mat-label>
          <input
            matInput
            type="date"
            data-cy="msa.form.date-input"
            required
            [(ngModel)]="input.model"
            [max]="maxDate"
            [min]="minDate"
            (input)="disableSubmit()"
          />
          <mat-hint class="msa-hint">{{ input.hint | translate }}</mat-hint>
          <mat-error
            *ngIf="shouldShowSelectError(input.model)"
            class="msa-important"
          >
            {{ 'msa_member_impersonate_length_error' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field
          *ngIf="input.type === 'select' && input.options"
          class="w-[350px]"
        >
          <mat-select
            [placeholder]="input.title | translate"
            [(value)]="input.model"
            data-cy="msa.form.select"
            (selectionChange)="disableSubmit()"
          >
            <mat-option
              *ngFor="let option of input.options; trackBy: trackByFn"
              [value]="option.id"
            >
              {{ option.name }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="shouldShowSelectError(input.model)"
            class="msa-important"
          >
            {{ 'msa_member_impersonate_select_error' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="msa-submit flex justify-center">
        <div class="w-1/3 flex">
          <zelis-button
            [fullWidth]="true"
            class="mr-4"
            data-cy="msa.form.submit-button"
            [variant]="'stroked'"
            color="accent"
            (clickFn)="postForm()"
            [disabled]="disableSubmitBtn"
          >
            {{ 'app_global_submit' | translate }}
          </zelis-button>
          <zelis-button
            [fullWidth]="true"
            [variant]="'stroked'"
            color="accent"
            (clickFn)="clearForm()"
            data-cy="msa.form.clear"
          >
            {{ 'msa_member_impersonate_button_clear' | translate }}
          </zelis-button>
        </div>
      </div>

      <div class="msa-logout">
        <p class="my-3">
          {{ 'msa_member_impersonate_session_logout_text' | translate }}
        </p>
        <zelis-button (clickFn)="logout()" variant="stroked" color="accent">
          <a>{{ 'msa_banner_logoutSession_button' | translate }}</a>
        </zelis-button>
      </div>
    </mat-card-content>
  </zelis-card>
</div>
